import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import * as actions_station from "../../home-page/home-page-store/HomePageAction";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import '../navbar.scss';
import dropdownIcon from '../../../assets/images/dropdown.svg';
import { useHistory } from 'react-router-dom';
import strings from "../../../resources/strings.json"

const AdminMenu = (props) => {

    const menuOptions = ["MyTime", "Mando"];

    const [ menuSelected, setMenuSelected ] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isMenuActive, setIsmenuActive] = useState(false);
    const [currentMenu, setCurrentMenu] = useState([]);
    const history = useHistory();

    const {
        setisAdminMenuOpen,
        isAdminMenuOpen
    } = props;

    const handleClickMenu = (e) => {
        if (history.location.pathname !== e.currentTarget.id) {
            props.action_station.navTreeReset();
        }
    }

    const handleMouseOver = (e) => {
        setIsActive(true)
        if (e.target.id == "Mando" || e.currentTarget.id == "Mando") {
            setMenuSelected('Mando')
        } else if (e.target.id == "MyTime" || e.currentTarget.id == "MyTime"){
            setMenuSelected('MyTime')
        }
    }

    useEffect(()=>{
        if (menuSelected=='Mando') {
            setCurrentMenu(props.navMenu.mando)
        } else if (menuSelected=='MyTime' && props.additionalAccess) {
            setCurrentMenu(props.navMenu.additionalMyTime)
        } else if (menuSelected=='MyTime' && !props.additionalAccess) {
            setCurrentMenu(props.navMenu.mytime)
        } else {
            setCurrentMenu([])
        }
    },[menuSelected, isActive])

    return (
        <>
       
        <div className='admin-container' onMouseLeave={()=>{
            setIsActive(false)
            setIsmenuActive(false)
            }} attr={`${isAdminMenuOpen}`} >
            <div className='admin-menu-title'>
                <div style={{display:'flex', marginLeft:10, alignItems: 'strech', justifyContent: 'flex-start'}}>
                    <p style={{marginLeft: 14, marginRight: 0, opacity: 0.9}}>Admin Menu</p><span style={{marginLeft: 20, marginRight: 25, borderWidth: 0.5, borderStyle: 'solid', marginTop: 5, marginBottom: 5, borderColor: 'white', opacity: 0.5}}></span>
                </div>
                {isAdminMenuOpen&&
                menuOptions.filter(menuItem=> {
                    if (strings.mytime_admin == localStorage.getItem(strings.adminType) || strings.mt_read_admin == localStorage.getItem(strings.adminType) ) {
                        return menuItem == 'MyTime'
                    }else if (strings.mando_admin == localStorage.getItem(strings.adminType) || strings.ot_read_admin == localStorage.getItem(strings.adminType)) {
                        return menuItem == 'Mando'
                    }else{
                            return menuItem
                    }
                }).map(menuItem=>{
                    return (
                    <div onMouseEnter={(e)=>handleMouseOver(e)} onMouseOver={(e)=>handleMouseOver(e)} onClick={handleClickMenu} attr={`${isAdminMenuOpen}`}
                        id={menuItem} className='admin-menu-item'>
                        <p style={{marginRight: 40}}>{menuItem}</p>
                    </div>)
                })
                } 
            </div>
            
            {(isActive || isMenuActive)&&currentMenu.length&&isAdminMenuOpen&&
            
            <div onMouseOver={(e)=>setIsmenuActive(true)} onMouseLeave={()=>{setIsActive(false)}} 
            className='submenu-container' style={{display: 'block'}}>
                <ul className='submenu-list'>
                {currentMenu.map(item=>{
                    if (item.permission.includes(localStorage.getItem(strings.adminType))) {
                        return (
                            <NavLink key={(item.title).toString()} path={item.nav} id={item.nav} className="submenu-item" onClick={(e)=>handleClickMenu(e)}
                            to={item.nav}>
                            <p style={{margin: 0, paddingTop: 10, paddingBottom: 10}}>{item.title}</p>
                        </NavLink>)
                    }
                })}
                </ul>
            </div>}
        </div>
      </>
    )
}

const mapStateToProps = (state) => ({
    navTree: state.HomePageReducer.navTree,
    isAdminMenuOpen: state.HomePageReducer.isAdminMenuOpen, 
    additionalAccess: state.HomePageReducer.additionalAccess
})

const mapDispatchToProps = (dispatch) => ({
    action_station: bindActionCreators({...actions_station}, dispatch),
    setisAdminMenuOpen: (params) =>
        dispatch({ type: "SET_ADMIN_MENU_OPEN", params:params }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
