import { navTree } from "./HomePageAction";

const initialState = {
  departmentsAll: [],
  departmentsNonAdmin: [],
  leaveTypes: [],
  miscTypes:[],
  navTree: [],
  empStationCode: '',
  activeRelease: '',
  additionalAccess: false,
  additionalProperties:[],
  fmlaPersonalTypes: [],
  fmlaCaregiverTypes: [],
  fmlaCaregiverPayTypes: [],
  fmlaPersonalPayTypes: [],
  bereavementRelationshipTypes: [],
  releaseInfo: {},
  absenceType: {},
  absenceStation: "",
  editAbsenceData: {},
  homePage: undefined,
  isAdminMenuOpen: false,
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {

    case "SET_ADMIN_MENU_OPEN": {
      return {
        ...state,
        isAdminMenuOpen: action.params
      }
    }
    case "NAV_TREE": {
      let newNavTree = state.navTree.length > 0 ? [...state.navTree]: [];
      if (action.payload == "Mando Dashboard") {
        newNavTree=["Mando Dashboard"];
      }
      else if (newNavTree.includes(action.payload)){
        let backToIdx = state.navTree.indexOf(action.payload)+1;
        newNavTree.slice(0, backToIdx);
      } else {
        newNavTree.push(action.payload);
      }
      return {
        ...state,
        navTree: newNavTree,
      }
    }
    case "NAV_TREE_RESET": {
      
      return {
        ...state,
        navTree: [],
      }
    }
    case "NAV_TREE_GO_BACK": {
      let cutOff = action.payload;
      let newNavTree = [...state.navTree];
      let idxCutOff = newNavTree.indexOf(cutOff)+1;
      newNavTree.splice(idxCutOff);
      return {
        ...state,
        navTree: newNavTree,
      }

    }
    case "SET_EMP_STATION": {
      return {
        ...state,
        empStationCode: action.payload,
      };
    }
    case "SET_ABSENCE_TYPE": {
      return {
        ...state,
        absenceType: action.payload,
      };
    }
    case "SET_ABSENCE_STATION": {
      return {
        ...state,
        absenceStation: action.payload,
      };
    }
    case "EDIT_ABSENCE_TYPE": {
      return {
        ...state,
        editAbsenceData: action.payload,
      };
    }

    case "RE_SET_EMP_STATION": {
      return {
        ...state,
        empStationCode: "",
      };
    }
    case "SET_RELEASE_DATA": {
      return {
        ...state,
        releaseInfo: action.payload,
      };
    }
    //
    case "SET_DATA": {
      let regDpt = action.payload?.departments || [];
      regDpt = regDpt.map((ele) => {
        if (ele.admin === false) return ele?.departmentName
      });

      let adminDpt = action.payload?.departments?.map(
        (ele) => ele?.departmentName)

      return {
        ...state,
        departmentsAll: adminDpt,
        departmentsNonAdmin: regDpt,
        leaveTypes: action.payload?.leaveTypes,
        miscTypes: action.payload?.miscellaneousLeaveTypes,
        additionalProperties: action.payload?.additionalProperties,
        homePage: action.payload?.additionalProperties?.homePage,
        fmlaPersonalTypes: action.payload?.fmlaPersonal,
        activeRelease: action.payload?.activeRelease,
        fmlaCaregiverTypes: action.payload?.fmlaCaregiver,
        fmlaCaregiverPayTypes: action.payload?.fmlaCaregiverPayTypes,
        fmlaPersonalPayTypes: action.payload?.fmlaPersonalPayTypes,
        bereavementRelationshipTypes: action.payload?.bereavementRelationshipTypes,
        additionalAccess: action.payload?.additionalProperties?.additionalAccess,
      }
    }
    case "RESET_DATA": {
      return initialState;
    }
    default: {
      return { ...state };
    }
  }
};

export default HomePageReducer;
