import resource from "../utils/resource.json";

const resources = resource[localStorage.getItem("envDetail")];

export const applyAbsence = () =>
  resources.base_url + "/v1/employee/applyAbsence";

export const department = (station) =>
  resources.base_url + `/v1/meta/${station}/department`;

export const departmentAbsence = () =>
  resources.base_url + `/v1/meta/absenceReport`;

export const getStations = (empId) =>
  resources.base_url + `/v1/meta/${empId}/stations`;

export const downloadHelpDocument = (fileName) =>
  resources.base_url + `/v1/document/help?fileName=${fileName}`;

export const deleteDocument = () =>
  resources.base_url + `/v1/document/help/delete`;

export const getDocumentList = () =>
  resources.base_url + `/v1/document/help/list`;

export const updateDocument = () => resources.base_url + `/v1/document/help`;

export const getAdminAbsenceReport = (station, department) =>
  resources.base_url +
  `/v1/reports/recent?station=${station}&department=${department}`;

export const searchAdminAbsenceReport = () =>
  resources.base_url + `/v1/reports/search`;

export const updateAdminAbsenceReport = () =>
  resources.base_url + `/v1/reports/update`;

export const latestAdminAbsenceReport = () =>
  resources.base_url + `/v1/reports/latest`;

export const employeeShift = (empId, station, shiftStartDate, shiftEndDate) =>
  resources.base_url +
  `/v1/employee/shifts?empId=${empId}&station=${station}&shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}`;

export const getSickBalance = (empId, station, shiftStartDate, shiftEndDate) =>
  resources.base_url +
  `/v1/employee/shifts/v2?empId=${empId}&station=${station}&shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}`;

export const adminManageReport = (station, operationType) =>
  resources.base_url + `/v1/meta/${station}/${operationType}/department`;

export const adminCreateUpdateRelease = () =>
  resources.base_url + `/v1/release`;

export const getAllReleases = () => resources.base_url + `/v1/release`;

export const getReleaseItem = (releaseNo) =>
  resources.base_url + `/v1/release/${releaseNo}`;

export const createReleaseitem = (releaseNo) =>
  resources.base_url + `/v1/release/${releaseNo}/item`;

export const employeeFeedback = () =>
  resources.base_url + `/v1/employee/feedback`;

export const stationIds = (uid) =>
  resources.base_url + `/v1/meta/${uid}/access/stations`;

export const exports = () => resources.base_url + `/v1/reports/export`;

export const mandoExport = (mandoId) => resources.MANDO_BASE_URL + `/mando/v1/reports/export?mandoId=${mandoId}`;

export const employeeProfile = (uid) =>
  resources.base_url + `/v1/meta/${uid}/profile`;

export const getAllStations = () => resources.base_url + `/v1/meta/stations`;

export const setAccessStations = (uid) =>
  resources.base_url + `/v1/meta/${uid}/access/stations`;
// export default { base_url };

export const recentAbsences1 = (uid, station, year) =>
  resources.base_url + `/v1/employee/absences?empId=${uid}&station=${station}`;

export const recentAbsences = (uid, station, year = 2024) =>
  resources.base_url + `/v1/employee/yearwiseabsences?empId=${uid}&station=${station}&year=${year}`;

export const cancelAbsenceRequest = () =>
  resources.base_url + `/v1/employee/cancelAbsence`;

export const manageAbsenceTypes = (station) =>
  resources.base_url + `/v1/station-absence/${station}`;

export const addEditAbsenseType = () =>
  resources.base_url + `/v1/station-absence`;

export const mandoOverTime = (station = "", date="") =>
  resources.MANDO_BASE_URL + `/mando/v1/mando-overtime?station=${station}`;

export const submitMandoOVerTime = () => 
  resources.MANDO_BASE_URL + `/mando/v1/mando-overtime`;

export const getOTGroup = (station) => 
  resources.MANDO_BASE_URL +`/mando/v1/otgroup?station=${station}`

export const getSBGroup = (station) => 
  resources.MANDO_BASE_URL +`/mando/v1/sbgroup?station=${station}`

export const myTimeProfile = () =>
  resources.MANDO_BASE_URL + `/mando/v1/userprofile`;

export const myTimeContact = () =>
  resources.MANDO_BASE_URL + `/mando/v1/user/contact`;

export const myTimeNotifications = () =>
  resources.MANDO_BASE_URL + `/mando/v1/user/notifications`;

export const confirmMandoRequest = () =>
  resources.MANDO_BASE_URL + `/mando/v1/employees`;

export const mandoOTInfo = (mandoId) => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoRequest?mandoId=${mandoId}`;

export const mandoEmployeeInfo = (mandoId) => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoemployees?mandoId=${mandoId}`;

export const mandoEmployeeRelease = () => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoReleaseEmployees`;

export const mandoEmployeeExtend = () => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoExtendEmployees`;

export const mandoCancel = () => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoCancel`;

export const mandoChange = () =>
  resources.MANDO_BASE_URL + `/mando/v1/mandoChange`;

export const getMandoChange = (mandoId) => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoChange?mandoId=${mandoId}`;

export const getChangeExtend = (changeId, mandoId) => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoExtension?changeId=${changeId}&mandoId=${mandoId}`;

export const getChangeRelease = (changeId, mandoId) => 
  resources.MANDO_BASE_URL + `/mando/v1/mandoRelease?changeId=${changeId}&mandoId=${mandoId}`;

export const mandoOverThirty = (station = "", date="") =>
  resources.MANDO_BASE_URL + `/mando/v1/mando-overthirty?station=${station}&thirtyDays=${date}`;

export const setHomePage = () =>
  resources.base_url + `/v1/admin/homepage`;

export const getWorkLocations = (station) =>
  resources.MANDO_BASE_URL + `/mando/v1/worklocation?station=${station}`;

export const getNotifications = (status) =>
  resources.MANDO_BASE_URL + `/mando/v1/employee/notification?status=${status}`

export const unreadCount = () =>
  resources.MANDO_BASE_URL + `/mando/v1/employee/notification/unreadcount`;

export const updateNotification = () =>
  resources.MANDO_BASE_URL + `/mando/v1/employee/notification/status`;

export const getClockInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/employee/punch`;

export const getUnionInfo = (station, department) =>
  resources.MANDO_BASE_URL + `/mando/v1/unionNotification?station=${station}&department=${department}`

export const updateUnionInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/unionNotification/enable`

export const deleteUnionInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/unionNotification/delete`

export const addUnionInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/unionNotification/new`

export const getDLInfo = (station) =>
  resources.MANDO_BASE_URL + `/mando/v1/DLNotification?station=${station}`

export const updateDLInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/DLNotification/enable`

export const deleteDLInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/DLNotification/delete`

export const addDLInfo = () =>
  resources.MANDO_BASE_URL + `/mando/v1/DLNotification/new`

export const confirmWBEntryAPI = () =>
  resources.MANDO_BASE_URL + `/mando/v1/confirmWBEntry`

export const calcCoverage = () =>
  resources.MANDO_BASE_URL + `/mando/v1/getCoverageNumber`

export const lateNotify = () =>
  resources.MANDO_BASE_URL + `/mando/v1/getLateNotifier`

export const newMyTimeContact = () =>
  resources.MANDO_BASE_URL + `/mando/v1/checkProfile`

export const getStationTime = (station) =>
  resources.MANDO_BASE_URL + `/mando/v1/stationTime?station=${station}`

export const postAdminFeedback = () =>
  resources.MANDO_BASE_URL + `/mando/v1/employee/feedback`

export const getStationList = () =>
  resources.MANDO_BASE_URL + `/mando/v1/stationList`

export const updateMOTResolution = () =>
  resources.MANDO_BASE_URL + `/mando/v1/resolveEmployeeError`

export const updateMOTResolutionBatch = () =>
  resources.MANDO_BASE_URL + `/mando/v1/resolveEmployeeErrorBatch`
