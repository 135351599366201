import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../authentication/AuthenticationAction";
import navTreeReset from "../../home-page/home-page-store/HomePageAction";
import { useHistory } from 'react-router-dom';
import { Navbar, Nav,  } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import strings from "../../../resources/strings.json"
import "../navbar.scss";
import SettingsIcon from "../../../assets/images/settings_icon.svg";
import HelpAndFeedback from "../../../assets/images/help_and_feedback_icon.svg";
import InformationIcon from "../../../assets/images/about_icon.svg";
import ShareIcon from "../../../assets/images/share_icon.svg";
import HamburguerMenu from "../../../assets/images/Web_Hamburger_Icon.svg";
import ProfileIcon from "../../../assets/images/Web_Profile_Icon.svg";
import HomeIcon from "../../../assets/images/home8.svg";
import LogoutIcon from "../../../assets/images/exit_icon.svg";
import Notify from '../../notification/notify';
import { unreadCount } from '../../../constants/constants';
import instance_api from '../../../config/axiosConfig';

function MobileLinks({ setOpenAbout, setShowRelease, action, navTreeReset, activeRelease, additionalAccess, setisAdminMenuOpen, isAdminMenuOpen, additionalProperties,setStationCodes, station}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const history = useHistory();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const handleClose = () => {
    setShowMainMenu(false)
  };

  const showNotification = () => {
    history.push("/notification");
    setShowMainMenu(false)
  }

  const getUnreadNotificationCount = async () => {
    const response = await instance_api("GET", unreadCount());
    if (response?.status === 200){
      if (response.data != []) {
          setUnreadNotificationCount(response.data);
      }
    }
    else {
      console.log("ERROR: " + response?.response?.status);
    }
  }

  const handleClickHamburgerMenu = () =>{
    setShowMainMenu(!showMainMenu)
  }

  const handleClick = () => {
    navTreeReset();
  }

  const handleAdminMenu = () => {
    if (!isAdminMenuOpen) setisAdminMenuOpen(true);
  }

  useEffect(() => {
    getUnreadNotificationCount();
  }, []);

  return (
    <>
    <Navbar
        collapseOnSelect
        className="mb-4"
        expand="md"
        bg="transparent"
        variant="light"
      >
        <div 
        className="container"
        >
          <Navbar.Collapse id="responsive-navbar-nav"
          style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}
          >
              <div onClick={showNotification} >
                <Notify width={"30px"} color={"#122C34"} count={unreadNotificationCount} />
              </div>
              <Nav>
                <NavLink onClick={handleClick}
                to="/mando-profile"
                >
                  <div 
                  style={{ color: "#122C34", marginLeft: 15 }}>
                    <img src={ProfileIcon} />
                  </div>
                </NavLink>
              </Nav>
              <Nav>
                {
                  localStorage.getItem(strings.adminType) != strings.normal &&
                <NavLink onClick={handleClick} to={'/'}>
                  <div 
                  style={{ color: "#122C34", marginLeft: 15 }}>
                    <img src={HomeIcon} />
                  </div>
                </NavLink>
                }
              </Nav>
                <div onClick={handleClickHamburgerMenu} style={{marginLeft: 15}}>
                  <img src={HamburguerMenu} />
                </div>
              {showMainMenu && <>
              <div className='hamburger-menu'>
               <ul>
               <div onClick={handleAdminMenu}>
                <li className="menu-item" onClick={handleClose}>
                  {strings.adminMenu}</li>
                </div>
                
                <NavLink to="/myTimeDashboard" onClick={handleClick}>
                <li className="menu-item" onClick={handleClose}>
                  {strings.myTimeDashboard}</li>
                </NavLink>

                <NavLink to="/helpDocuments" onClick={handleClick}>
                <li className="menu-item" onClick={handleClose}>
                  {strings.helpDocument}</li>
                </NavLink>

                <NavLink to="/download-mobile" onClick={handleClick}>
                <li className="menu-item" onClick={handleClose}>
                  {strings.downloadMobile}</li>
                </NavLink>

                <NavLink to="/links" onClick={handleClick}>
                <li className="menu-item" onClick={handleClose}>
                  {strings.links}</li>
                </NavLink>

                <li className="menu-item" onClick={() => { handleClose(); setOpenAbout(true); handleClick() }}>
                  {strings.menuAbout}</li>

                <li className="menu-item" component="a" href="#" onClick={() => { action.logoutSuccess(); handleClick()}}>
                  {strings.logout}</li>
              </ul>
              </div>
              <div
                    className="searchBlur active"
                    onClick={handleClose}
                  ></div>
                  </>}
          </Navbar.Collapse>

          </div>
      </Navbar>
      </>
  );
}

const mapStateToProps = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
  exp: state.AuthenticationReducer.exp,
  activeRelease: state.HomePageReducer.activeRelease,
  additionalAccess: state.HomePageReducer.additionalAccess,
  isAdminMenuOpen: state.HomePageReducer.isAdminMenuOpen,
  additionalProperties: state.HomePageReducer.additionalProperties,
  station: state.AuthenticationReducer.station,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
  navTreeReset: (params) =>
    dispatch({ type: "NAV_TREE_RESET", params: params }),
  setisAdminMenuOpen: (params) =>
    dispatch({ type: "SET_ADMIN_MENU_OPEN", params: params }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileLinks);
