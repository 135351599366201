export const setData = (payload) => ({
  type: "SET_DATA",
  payload,
});

export const resetData = () => ({
  type: "RESET_DATA",
});

export const setEmpStation = (payload) => ({
  type: "SET_EMP_STATION",
  payload,
});

export const resetEmpStation = (payload) => ({
  type: "RE_SET_EMP_STATION",
  payload,
});

export const setReleaseInfo = (payload) => ({
  type: "SET_RELEASE_DATA",
  payload,
});
export const setAbsenceType = (payload) => ({
  type: "SET_ABSENCE_TYPE",
  payload,
});

export const setAbsenceStation = (payload) => ({
  type: "SET_ABSENCE_STATION",
  payload,
});

export const editAbsenceType = (payload) => ({
  type: "EDIT_ABSENCE_TYPE",
  payload,
});

export const navTree = (payload) => ({
  type: "NAV_TREE",
  payload,
});

export const navTreeReset = (payload) => ({
  type: "NAV_TREE_RESET",
  payload,
});

export const navTreeGoBack = (payload) => ({
  type: "NAV_TREE_GO_BACK",
  payload,
})
